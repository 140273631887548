import { CrudTypes } from './types'

import api from '../../util/api'

// get locations
export const getLocations = () => async dispatch => {
  try {
    setLoading()

    const result = await api.get('location')

    dispatch({
      type: CrudTypes.GET_LOCATIONS,
      payload: result.data.data
    })
  } catch (error) {
    dispatch({
      type: CrudTypes.GET_LOCATIONS_ERROR,
      payload: error
    })
  }
}

// Get location by ID
export const getLocationByID = id => async dispatch => {
  try {
    setLoading()

    const result = await api.get(`location/${id}`)

    dispatch({
      type: CrudTypes.GET_LOCATION,
      payload: result.data.data
    })
  } catch (error) {
    dispatch({
      type: CrudTypes.GET_LOCATIONS_ERROR,
      payload: error
    })
  }
}

// clear errors
export const clearErrors = () => dispatch => {
  dispatch({ type: CrudTypes.CLEAR_ERRORS })
}

// Set loading
export const setLoading = () => dispatch => {
  dispatch({ type: CrudTypes.SET_LOADING })
}
