import React, { useState, useEffect } from 'react'
import { List, Button, Avatar } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

let _data = []

/**
 * ListDOC
 *
 * @version 0.1
 * @date 17.04.2021
 *
 * @param  props.bgImage - background image of banner url or const
 * @param  props.overlayColor - custom color with rgba, hex or hex8
 * @param  props.title - title of banner
 * @param  props.subtitle - subtitle of banner
 * @param  props.height - height of banner in pixels, default 190px
 * @param  props.link - link of banner
 * @param  props.color - color of list item
 */

const ListDOC = ({ data, loadMore, itemsPerClick, loadMoreTitle, itemData, color }) => {
  const SList = styled(List)`
    .ant-list-item {
      color: ${color} !important;
      border-bottom: 1px dashed #888 !important;
    }

    .ant-list-item-meta-content span {
      color: ${color} !important;
    }

    .ant-list-item-meta-description {
      color: ${color} !important;
    }
  `
  // Wrap Ant List component to Styled

  const [dataToShow, setDataToShow] = useState([])
  const [next, setNext] = useState(itemsPerClick)
  const [loaded, setLoaded] = useState(false)

  const loadPartData = end => {
    if (!loaded) {
      const slicedData = data.slice(0, end)
      _data = slicedData
      setDataToShow(_data)
      // Disable button if all items loaded
    }
    if (data.length > 0 && end >= data.length) setLoaded(true)
  }

  useEffect(() => {
    if (data) {
      if (data.length <= 5) {
        setLoaded(true)
      } else {
        setLoaded(false)
      }
      setDataToShow(data.slice(0, 5))
    }
  }, [data])

  /*  // eslint-disable-next-line
  useEffect(() => {
    // Split data array only with loadmore = true
    if (loadMore) {
      console.log('useEffect 0, ', itemsPerClick)
      // loadPartData(0, itemsPerClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
 */
  const onLoadMore = () => {
    loadPartData(next + itemsPerClick)
    setNext(next + itemsPerClick)
  }

  const handleLoadMore =
    !loaded && dataToShow.length > 0 ? (
      <div style={{ margin: '15px 0' }}>
        <Button block type='primary' disabled={loaded} onClick={onLoadMore}>
          {loadMoreTitle}
        </Button>
      </div>
    ) : (
      ''
    )

  return loadMore ? (
    <SList
      dataSource={dataToShow}
      loadMore={handleLoadMore}
      renderItem={item => (
        <span onClick={() => itemData(item)}>
          <List.Item key={dataToShow.indexOf(item)}>
            <List.Item.Meta
              avatar={item.image ? <Avatar size={item.image.size} shape={item.image.shape} src={item.image.src} /> : ''}
              description={item.subtitle}
              title={<span>{item.title}</span>}
            />
            <p>{item.amount} KM</p>
          </List.Item>
        </span>
      )}
    ></SList>
  ) : (
    <SList
      dataSource={data ? data : []} //(data.length > 0 ? data : [], console.log('data not defined'))
      renderItem={item => (
        <span onClick={() => itemData(item)}>
          <List.Item key={data.indexOf(item)}>
            <List.Item.Meta
              avatar={item.image ? <Avatar size={item.image.size} shape={item.image.shape} src={item.image.src} /> : ''}
              description={item.subtitle}
              title={<span>{item.title}</span>}
            />
            <>{item.amount}</>
          </List.Item>
        </span>
      )}
    ></SList>
  )
}
export default ListDOC

ListDOC.defaultProps = {
  /** How many items to display per click */
  itemsPerClick: 5,
  /** Show button load more */
  loadMore: false,
  /** Title of load more button */
  loadMoreTitle: 'Load more...',
  /** Color of list items */
  color: 'white'
}

ListDOC.propTypes = {
  itemsPerClick: PropTypes.number,
  loadMore: PropTypes.bool,
  color: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      amount: PropTypes.string,
      image: PropTypes.shape({
        size: PropTypes.number,
        shape: PropTypes.oneOf(['square', 'cicrle']),
        src: PropTypes.string
      }),
      extra: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        link: PropTypes.string
      })
    })
  )
}
