import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import './App.scss'
import LocationPage from './pages/locations/Location'
import HomePage from './pages/home/'

import { Provider } from 'react-redux'
import store from './redux/store'

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path='/' component={HomePage}></Route>
          <Route exact path='/:id' component={LocationPage}></Route>
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
