import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Button, Modal, Divider, Dropdown, Carousel, Spin } from 'antd'
import { AccountBookOutlined, DownOutlined, TagsOutlined, LoadingOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { getLocationByID } from '../../redux/crud/actions.js'

// DOC's Components
import { MenuDOC } from '../../components/MenuDOC'
import ListDOC from '../../components/ListDOC'
import Heading from '../../components/Heading'
import Banner from '../../components/Banner'
import './Location.scss'

// Dummy data for testing
import { LIST_WITH_ICONS } from './data.js'
const { Item } = MenuDOC

const Location = ({ crud: { location, loading }, getLocationByID }) => {
  const { id } = useParams()
  const [showModal, setShowModal] = useState(false)
  const [showModalPonude, setShowModalPonude] = useState(false)
  const [modelData, setModelData] = useState({})
  const [listBasic, setListBasic] = useState([])

  const [availableMenus, setAvailableMenus] = useState([])

  const [dataMenus, setDataMenus] = useState([])

  const [availableSubMenu, setAvailableSubMenu] = useState([])

  const [menuSelected, setMenuSelected] = useState('none')

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  const ModalInfo = () => {
    Modal.info({
      title: 'ZAKONSKI OKVIR',
      content: (
        <div>
          <p>
            "Poštovani kupci,
            <br />U skladu sa zakonom o ugostiteljskoj djelatnosti član 11, stav 6, i pravilnikom o primjeni zakona o
            porezu na dodatnu vrijednost član 114, ugostitelj je dužan izdati gostu račun za svaku pruženu ugostiteljsku
            uslugu. U skladu sa članom 4, zakona o deviznom poslovanju, službene novine FBIH 47/10, naplata se ne može
            vršiti u stranim valutama. Zabranjeno služiti alkohol maloljetnim licima. Knjiga žalbi nalazi se na šanku."
          </p>
        </div>
      ),
      centered: true,
      maskClosable: true,
      okButtonProps: { disabled: true, style: { display: 'none' } },
      icon: null
    })
  }

  const handleShowItem = data => {
    setShowModal(true)
    setModelData(data)
  }

  const loadData = () => {
    if (location.menu) {
      const menu = location.menu.bs // array menija (piće, hrana, etc)
      setAvailableSubMenu(menu[0].subcategories)
      //   console.log('menu[0].subcategories.items: ', menu[0].subcategories.items)
      // setListBasic(listBasic => [...listBasic, elementObject])
      menu.forEach(element => {
        const menuName = element.name
        if (menuName !== 'RedBull') {
          setAvailableMenus(availableMenus => [...availableMenus, menuName])
          setDataMenus(dataMenus => [...dataMenus, element])
        }
        // console.log('Meni: ', menuName)
        /* element.subcategories.forEach(sub => {
          const subMenuName = sub.name
          //  console.log('Submenu: ', sub.name)
          const subMenuItems = sub.items
          //  console.log('Items: ', subMenuItems)
          subMenuItems.forEach(element => {
            const elementObject = {
              title: element.name,
              amount: element.price.toString(),
              link: 'https://doc.ba',
              menu: menuName,
              sub: subMenuName
            }
            //setListBasic(listBasic => [...listBasic, elementObject])
            //setAvailableSubMenu(elementObject[0].subcategories)
          })
        }) */
      })
    }
  }

  useEffect(() => {
    getLocationByID(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const odaberiMeni = meni => {
    dataMenus
      .filter(menu => menu.name === meni)
      .map(selectedMeni => {
        console.log('selectedMeni.subcategories: ', selectedMeni.subcategories)

        if (selectedMeni.subcategories.length <= 1) {
          setAvailableSubMenu([])
          document.getElementsByClassName('customDropdown')[0].style.display = 'none'
        } else {
          setAvailableSubMenu(selectedMeni.subcategories)
          document.getElementsByClassName('customDropdown')[0].style.display = 'block'
        }

        let newArray = []

        selectedMeni.subcategories.forEach(sub => {
          sub.items.forEach(element => {
            const elementObject = {
              title: element.name,
              amount: element.price.toString(),
              link: 'https://doc.ba',
              description: element.description
            }
            newArray.push(elementObject)
          })
        })
        /* 
        selectedMeni.subcategories[0].items.forEach(element => {
          const elementObject = { title: element.name, amount: element.price.toString(), link: 'https://doc.ba' }
          newArray.push(elementObject)
        }) */
        setListBasic(newArray)
        setMenuSelected('block')
        return selectedMeni
      })
  }

  const selectSubMenu = submenu => {
    // console.log('odabrani submeni je: ', submenu)
    let newArray = []
    submenu.items.forEach(element => {
      const elementObject = { title: element.name, amount: element.price.toString(), link: 'https://doc.ba' }
      newArray.push(elementObject)
    })
    setListBasic(newArray)
  }

  const kompletMenu = (
    <MenuDOC theme='dark' className='sub-menu'>
      {availableSubMenu.map((submenu, index) => {
        return (
          <Item key={index} onClick={() => selectSubMenu(submenu)}>
            {submenu.name}
          </Item>
        )
      })}
    </MenuDOC>
  )

  const dnevnaPonudaImg = {
    height: '100%',
    width: '100%'
  }

  return (
    <div className='main-wrapper' style={{ height: '100vh', width: '100vw' }}>
      <Spin spinning={loading} indicator={antIcon} size={'large'} style={{ margin: '48vh 0 0 0' }}>
        <div className='locations-wrapper' style={{ display: !loading ? 'block' : 'none' }}>
          {/* Items modal */}
          <Modal
            title={null}
            footer={null}
            visible={showModal}
            maskClosable={true}
            centered={true}
            onCancel={() => setShowModal(false)}
          >
            {modelData.image ? (
              <div style={{ textAlign: 'center' }}>
                <img width='250' src={modelData.image.src} alt='model' />
              </div>
            ) : (
              ''
            )}
            <h2 className='modalTitle'>{modelData.title}</h2>
            <Divider />
            <h3>{modelData.amount} KM</h3>
            <p> {modelData.description ? 'Opis: ' + modelData.description : ' '}</p>
            <Divider />
            <h1>{modelData.extra ? modelData.extra.title : ''}</h1>
            <p>{modelData.extra ? modelData.extra.description : ''}</p>
            {modelData.extra && modelData.extra.link ? (
              <Button type='primary' danger href={modelData.extra.link}>
                Naruči više
              </Button>
            ) : (
              ''
            )}
          </Modal>
          {/* Dnevna ponuda modal */}
          <Modal
            className='dnevnePonude'
            title={null}
            footer={null}
            visible={showModalPonude}
            maskClosable={true}
            mask={true}
            centered={true}
            onCancel={() => setShowModalPonude(false)}
          >
            <Carousel autoplay dots={false}>
              <div>
                <img
                  style={dnevnaPonudaImg}
                  src='https://thecmenu.com/wp-content/uploads/2020/12/RED-BULL-Dnevna-Aktivacija-Energy-Drink.jpg'
                  alt='Red Bull Dnevna Ponuda'
                />
              </div>
              <div>
                <img
                  style={dnevnaPonudaImg}
                  src='https://thecmenu.com/wp-content/uploads/2020/12/RED-BULL-Dnevna-Aktivacija-Sugarfree.jpg'
                  alt='Red Bull Sugar Free Dnevna Ponuda'
                />
              </div>
            </Carousel>
          </Modal>
          <Row>
            <Heading
              bgImage={
                location.image ||
                'https://videohive.img.customer.envatousercontent.com/files/0e6248c9-24d6-41e5-86e3-e432cd3ae030/inline_image_preview.jpg?auto=compress%2Cformat&fit=crop&crop=top&max-h=8000&max-w=590&s=cc1401e986b854d1151d8554b74de328'
              }
              title={location.location_type}
              link={location.social_link}
              subtitle={location.name}
              overlayColor='#00000095'
              height={190}
            />
          </Row>
          <Row style={{ padding: '10px 0 0 0', display: location.daily_menu ? 'block' : 'none' }}>
            <Col span={24} style={{ color: 'white' }}>
              <MenuDOC theme='dark' mode='horizontal' className='main-menu dnevna-ponuda'>
                <Item icon={<TagsOutlined />} onClick={() => setShowModalPonude(true)}>
                  DNEVNA PONUDA!
                </Item>
              </MenuDOC>
            </Col>
          </Row>
          <Row style={{ padding: '10px 0' }}>
            <Col span={24} style={{ color: 'white' }}>
              <MenuDOC theme='dark' mode='horizontal' className='main-menu'>
                {availableMenus.map((menu, index) => {
                  // icon={<AccountBookOutlined />}
                  return (
                    <Item key={index} onClick={() => odaberiMeni(menu)}>
                      {menu}
                    </Item>
                  )
                })}
              </MenuDOC>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: 'center', display: menuSelected }}>
              <Dropdown overlay={kompletMenu} trigger={['click']}>
                <div className='customDropdown'>
                  <p className='ant-dropdown-link' onClick={e => e.preventDefault()}>
                    Izaberi podkategoriju <DownOutlined />
                  </p>
                </div>
              </Dropdown>
            </Col>
          </Row>
          {listBasic.length > 0 ? (
            <Row style={{ padding: 0, color: 'white' }}>
              <Col span={24}>
                <ListDOC
                  data={listBasic}
                  color='white'
                  loadMore
                  itemData={item => handleShowItem(item)}
                  loadMoreTitle='Učitaj više...'
                />
              </Col>
            </Row>
          ) : (
            ''
          )}

          {/* LIST ITEMS WITH AVATAR + SUB TITLE */}
          <Row style={{ padding: 0, color: 'white', display: location.redbull_object ? 'block' : 'none' }}>
            <Col span={24}>
              <ListDOC data={LIST_WITH_ICONS} itemData={item => handleShowItem(item)} />
            </Col>
          </Row>
          {/* Red Bull banners */}
          <Row style={{ display: location.redbull_object ? 'block' : 'none' }}>
            <Banner
              title='Follow US'
              subtitle='@RedBullBiH Instagram'
              bgImage='https://thecmenu.com/wp-content/uploads/2020/11/Majstor-Parkour.jpg'
              button={{ title: 'Zaprati nas!' }}
              link='https://www.instagram.com/redbullbih/'
            />
            <Banner
              title='Novosti i dešavanja'
              subtitle='RedBull.ba'
              bgImage='https://thecmenu.com/wp-content/uploads/2020/11/Dzanan-Musa.jpg'
              button={{ title: 'Saznaj više' }}
              link='https://www.redbull.com/'
            />
          </Row>
          {/* Korpa banner */}
          <Row style={{ display: location.korpa_url ? 'block' : 'none' }}>
            <Banner
              title='AKO STE KUĆI I ŽELITE NARUČITI'
              subtitle='Naruči preko korpa.ba'
              bgImage='https://thecmenu.com/wp-content/uploads/2020/11/Korpa.ba-Banner.png'
              button={{ title: 'NARUČI' }}
              link={location.korpa_url}
            />
          </Row>
          {/* <Banner bgImage='https://image.freepik.com/free-vector/space-background-with-planets-flat-design_23-2147660733.jpg'>
          <span>SPACE</span>
          <h4>Lorem ipsum dolor sit.</h4>
          <Button type='primary'>Click me</Button>
        </Banner>
        <Banner
          overlayColor='#00000030'
          bgImage='https://i.pinimg.com/originals/88/47/9c/88479c19fb7d3250b23c720d9fc170f0.jpg'
        >
          <span>CUSTOM TITLE</span>
          <h4>custom subtitle</h4>
          <Button type='primary' danger>
            BUTTON
          </Button>
        </Banner> */}

          <Row style={{ padding: '10px 0' }}>
            <div className='footer'>
              <a href='https://doc.ba' target='_blank' rel='noreferrer'>
                <img src='https://thecmenu.com/wp-content/uploads/2020/11/Logo-White-CMENU.svg' alt='' />
              </a>
              <p onClick={ModalInfo}>Zakonski okvir</p>
              <p style={{ opacity: '0' }}>Lang</p>
            </div>
          </Row>
        </div>
      </Spin>
    </div>
  )
}
const mapStateToProps = state => ({
  crud: state.crud
})

export default connect(mapStateToProps, { getLocationByID })(Location)
