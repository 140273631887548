const _icon = 'https://thecmenu.com/wp-content/uploads/2020/11/Redbull-Energy-Drink_otvorena-400x400.png'
const _iconSF = 'https://thecmenu.com/wp-content/uploads/2021/01/RedBull-SF-NEW-400x400.png'
const _iconRE = 'https://thecmenu.com/wp-content/uploads/2021/02/RedBull-Red-Edition-01-400x400.png'
const _iconRuE = 'https://thecmenu.com/wp-content/uploads/2021/03/Red-Bull-Ruby-Edition-01-400x400.png'
const _iconWE = 'https://thecmenu.com/wp-content/uploads/2020/11/RedBull-White-Edition-400x400.png'
const _iconGE = 'https://thecmenu.com/wp-content/uploads/2020/11/RedBull-GreenEdition_otvorena-400x400.png'

export const LIST_BASIC = [
  { title: 'Expresso', amount: '10,00 KM', link: 'https://doc.ba' },
  { title: 'Caffe Macchiato', amount: '10,00 KM', link: 'https://doc.ba' },
  { title: 'Cappuccino', amount: '10,00 KM', link: 'https://doc.ba' },
  { title: 'Topla čokolada sa mlijekom', amount: '10,00 KM', link: 'https://doc.ba' },
  { title: 'Olimpija', amount: '10,00 KM', link: 'https://doc.ba' },
  { title: 'Limeta', amount: '10,00 KM', link: 'https://doc.ba' },
  { title: 'Malina', amount: '10,00 KM', link: 'https://doc.ba' },
  { title: 'Item 1', amount: '10,00 KM', link: 'https://doc.ba' },
  { title: 'Item 2', amount: '10,00 KM', link: 'https://doc.ba' },
  { title: 'Item 3', amount: '10,00 KM', link: 'https://doc.ba' },
  { title: 'Item 4', amount: '10,00 KM', link: 'https://doc.ba' }
]
export const LIST_WITH_ICONS = [
  {
    title: 'Red Bull Energy Drink',
    subtitle: '0,25l',
    amount: '4,00',
    image: { size: 70, shape: 'square', src: _icon },
    extra: {
      title: 'Ozivljava tijelo i duh',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt rerum, voluptates tempora ratione accusantium expedita quo nobis magni sequi delectus.',
      link: 'https://doc.ba'
    }
  },
  {
    title: 'Red Bull Sugarfree',
    subtitle: '0,25l',
    amount: '4,00',
    image: { size: 70, shape: 'square', src: _iconSF },
    extra: {
      title: 'Ozivljava tijelo i duh',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt rerum, voluptates tempora ratione accusantium expedita quo nobis magni sequi delectus.',
      link: 'https://doc.ba'
    }
  },
  {
    title: 'Red Bull White Edition',
    subtitle: '0,25l',
    amount: '4,00',
    image: { size: 70, shape: 'square', src: _iconWE },
    extra: {
      title: 'Ozivljava tijelo i duh',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt rerum, voluptates tempora ratione accusantium expedita quo nobis magni sequi delectus.',
      link: 'https://doc.ba'
    }
  }
]
