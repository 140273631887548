import React from 'react'
import PropTypes from 'prop-types'
import { Menu } from 'antd'
const { Item, SubMenu, ItemGroup } = Menu

/**
 * Menu based on AntDesign menu with one level span
 *
 * @param  props.menuItems - array of objects with menu and submenu items
 * @param  props.antdmenu - use any build-in AntDesign Menu props
 */
export const MenuDOC = ({ menuItems, children, ...rest }) => {
  if (!menuItems) return <Menu {...rest}>{children}</Menu>

  const constructMenu = menuItems.map((item, index) => {
    return item.routes && item.routes.length > 0 ? (
      <SubMenu
        key={`sub${index + 1}`}
        icon={item.icon && item.icon !== '' ? React.createElement(item.icon) : null}
        title={item.name && item.name !== '' ? item.name : `No title ${index}`}
      >
        {item.routes.map((subItem, subIndex) => {
          return (
            <Item
              key={`${index + 1}-${subIndex + 1}`}
              icon={subItem.icon && subItem.icon !== '' ? React.createElement(subItem.icon) : null}
            >
              {subItem.link ? subItem.link : subItem.name ? subItem.name : `Item ${subIndex}`}
            </Item>
          )
        })}
      </SubMenu>
    ) : (
      <Item key={index + 1} icon={item.icon && item.icon !== '' ? React.createElement(item.icon) : null}>
        {item.link ? item.link : item.name ? item.name : `Item ${index}`}
      </Item>
    )
  })
  return <Menu {...rest}>{constructMenu}</Menu>
}

MenuDOC.Item = Item
MenuDOC.SubMenu = SubMenu
MenuDOC.ItemGroup = ItemGroup

MenuDOC.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Path to link
       */
      path: PropTypes.string,
      /**
       * Path to component that need to be rendered
       */
      component: PropTypes.string,
      /**
       * Name of menu item
       */
      name: PropTypes.string,
      /**
       * Icon object from @ant/icons
       */
      icon: PropTypes.object,
      /**
       * Need to be used with React Router Link
       */
      link: PropTypes.object,
      /**
       * Used for multilevel menu
       */
      routes: PropTypes.array
    })
  )
}
