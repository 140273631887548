import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import styled from 'styled-components'

/**
 * Banner (basic)
 * This is first basic banner of DOC Components
 *
 * @param  props.bgImage - background image of banner url or const
 * @param  props.children - use only with custom banner structure
 * @param  props.title - title of banner
 * @param  props.subtitle - subtitle of banner
 * @param  props.link - link of banner
 * @param  props.height - height of banner in pixels, default 190px
 * @param  props.button - object with keys: title, type, danger
 * @param  props.overlayColor - custom color with rgba, hex or hex8
 */

const Banner = ({ bgImage, children, title, link, subtitle, height, button, overlayColor }) => {
  const _height = height

  // Body class
  const Body = styled.div`
    background-image: url(${bgImage});
    height: ${_height}px;
    min-height: ${_height}px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
  `
  // Overlay class
  const Overlay = styled.div`
    background-color: ${overlayColor};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px;
    border-radius: 5px;
  `
  // Wrapper class
  const Wrapper = styled.div`
    position: relative;
    width: 100%;
    margin: 10px 0 0 0;
    overflow: hidden;

    & a {
      color: unset;
      text-decoration: none;
    }
  `
  // Content class
  const Content = styled.div`
    line-height: 28px;
    & span {
      display: block;
      color: white;
      font-size: 14px;
      text-transform: uppercase;
    }
    & h4 {
      color: white;
      font-size: 24px;
      font-weight: 500;
    }
  `
  return (
    <Wrapper>
      <a href={link} target='_blank' rel='noreferrer'>
        <Body>
          <Overlay>
            <Content>
              {children ? (
                children
              ) : (
                <>
                  <span>{title}</span>
                  <h4>{subtitle}</h4>
                  <Button type={button.type || 'primary'} danger={button.danger || false}>
                    {button.title}
                  </Button>
                </>
              )}
            </Content>
          </Overlay>
        </Body>
      </a>
    </Wrapper>
  )
}
export default Banner

Banner.propTypes = {
  /** Height of banner */
  height: PropTypes.number,
  /** Background image of banner */
  bgImage: PropTypes.string,
  /** Href to page (default _blank) */
  link: PropTypes.string,
  /** Title of banner */
  title: PropTypes.string,
  /** Subtitle of banner */
  subtitle: PropTypes.string,
  /** Overlay color of banner */
  overlayColor: PropTypes.string,
  /** Button with props: title, type */
  button: PropTypes.object
}

Banner.defaultProps = {
  height: 190,
  bgImage: 'https://via.placeholder.com/500x400.png/6F1A83/fff?text=doc.ba',
  link: 'https://doc.ba',
  title: 'title',
  subtitle: 'subtitle',
  overlayColor: 'rgba(0, 0, 0, 0.3)',
  button: {
    title: 'button',
    type: 'primary',
    danger: true
  }
}
