import React, { useRef } from 'react'
import { Layout, Row, Col, Button, Table, Space, Card } from 'antd'

import chatIcon from '../../assets/images/chat-bubble.svg'
import formIcon from '../../assets/images/form.svg'
import qrcodeIcon from '../../assets/images/qrcode.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faCopyright } from '@fortawesome/free-regular-svg-icons'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

import './style.css'

const HomePage = () => {
  const vidiJos = useRef(null)
  const cta = useRef(null)
  const { Content, Footer } = Layout
  const { Meta } = Card

  const scrollVidiJos = param => vidiJos.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) // run this function from an event handler or pass it to useEffect to execute scroll
  const scrollCta = param => cta.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) // run this function from an event handler or pass it to useEffect to execute scroll

  const dataSource = [
    {
      key: '1',
      paketi: 'Contactless Menu',
      basic: 'Sa reklamama',
      plus: 'Bez reklama',
      premium: 'Bez reklama'
    },
    {
      key: '2',
      paketi: 'Osnovne informacije',
      basic: <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>,
      plus: <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>,
      premium: <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
    },
    {
      key: '3',
      paketi: 'Proširene informacije',
      basic: <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>,
      plus: <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>,
      premium: <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
    },
    {
      key: '4',
      paketi: 'QR Code za print naljepnica',
      basic: <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>,
      plus: <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>,
      premium: <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
    },
    {
      key: '5',
      paketi: 'Meni na više jezika',
      basic: <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>,
      plus: 'Dvojezični',
      premium: 'Višejezični'
    },
    {
      key: '6',
      paketi: 'Prilagodba dizajna',
      basic: <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>,
      plus: <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>,
      premium: <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
    },
    {
      key: '7',
      paketi: 'Podrška',
      basic: <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>,
      plus: 'Email',
      premium: 'Online chat'
    },
    {
      key: '8',
      paketi: 'Mogućnost narudžbe kroz cMenu',
      basic: <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>,
      plus: <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>,
      premium: <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
    },
    {
      key: '9',
      paketi: "Opcija 'pozovi konobara'",
      basic: <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>,
      plus: <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>,
      premium: <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
    },
    {
      key: '10',
      paketi: 'Promocija objekta kao istaknutog na cMenu platformi',
      basic: <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>,
      plus: <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>,
      premium: <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
    },
    {
      key: '11',
      paketi: 'Upravljanje Google My Business platformom',
      basic: <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>,
      plus: <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>,
      premium: <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
    }
  ]

  const columns = [
    {
      title: () => {
        return <img src='https://thecmenu.com/wp-content/uploads/2020/10/cmenu-paketi.svg' alt='cMenu paketi' />
      },
      dataIndex: 'paketi',
      key: 'paketi'
    },
    {
      title: 'Basic',
      dataIndex: 'basicp',
      key: 'basicp',
      children: [
        {
          title: '€40.00',
          dataIndex: 'price',
          key: 'price',
          children: [
            {
              title: 'JEDNOKRATNO PLAĆANJE',
              dataIndex: 'type',
              key: 'type',
              children: [{ title: '', dataIndex: 'basic', key: 'basic' }]
            }
          ]
        }
      ]
    },
    {
      title: 'Plus',
      dataIndex: 'plusp',
      key: 'plusp',
      children: [
        {
          title: '€12.99',
          dataIndex: 'price',
          key: 'price',
          children: [
            {
              title: 'MJESEČNO',
              dataIndex: 'type',
              key: 'type',
              children: [
                {
                  title: '(MOGUĆE PLAĆATI GODIŠNJE)',
                  dataIndex: 'plus',
                  key: 'plus'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      title: 'Premium',
      dataIndex: 'premiump',
      key: 'premiump',
      children: [
        {
          title: '€22.99',
          dataIndex: 'price',
          key: 'price',
          children: [
            {
              title: 'MJESEČNO',
              dataIndex: 'type',
              key: 'type',
              children: [
                {
                  title: '(MOGUĆE PLAĆATI GODIŠNJE)',
                  dataIndex: 'premium',
                  key: 'premium'
                }
              ]
            }
          ]
        }
      ]
    }
  ]

  return (
    <Layout>
      <Content style={{ backgroundColor: 'white' }}>
        <Row justify='space-around' align='middle' className='hero'>
          <Col md={24} style={{ textAlign: 'center' }}>
            <img
              src='https://thecmenu.com/wp-content/uploads/2020/11/cmenu-Logo-Dark-Small-black.svg'
              alt='cMenu logo'
            />
          </Col>
          <Col sm={24} md={10} className='pt-3'>
            <h1 className='hero-title'>Contactless Menu</h1>
            <h3 className='hero-subtitle'>Brz, jednostavan i siguran način naručivanja za goste u Vašem objektu!</h3>
            <p className='p-content'>
              Neka Vaši gosti budu u mogućnosti pogledati ponudu Vašeg objekta na potpuno siguran i brz način, kao i
              naručiti neki od artikala putem jednostavnog beskontaktnog menija u Vašem objektu.
            </p>
            <Space>
              <Button className='hero-btn-purple' onClick={scrollVidiJos}>
                Vidi još
              </Button>
              <Button className='hero-btn-gray' onClick={scrollCta}>
                Želite svoj cMenu?
              </Button>
            </Space>
          </Col>
          <Col sm={24} md={14} className='pt-3'>
            <img
              src='https://thecmenu.com/wp-content/uploads/2020/10/Section-Image-1-3.png'
              style={{ float: 'right' }}
              alt='cMenu mobile preview'
            />
          </Col>
        </Row>
        <Row className='vertical-space-gray'></Row>
        <Row style={{ marginTop: '-300px' }}>
          <div className='row70 mlr-auto pt-3'>
            <Col span={24} style={{ textAlign: 'center' }}>
              <img src='https://thecmenu.com/wp-content/uploads/2020/10/Section-Image-2-1.png' alt='cMenu scan' />
              <h4 className='section-title pt-3 row70 mlr-auto mb-10'>
                QR kod za pregled i naručivanje hrane i pića u Vašem objektu!
              </h4>
              <p className='p-content row70 mlr-auto'>
                Skeniranjem QR koda putem svojih pametnih telefona, Vaši gosti će putem istih moći da pregledaju ponudu,
                slike, opise Vaših artikala, kao i da naruče ili pozovu konobara.
              </p>
            </Col>
          </div>
        </Row>
        <div className='purple-bg mt-15 mb-15'>
          <Row align='middle' className='row70 mlr-auto' style={{ height: '100%' }}>
            <Col sm={24} md={9}>
              <div style={{ margin: '-50px auto 0 auto' }}>
                <h3 className='white-title'>Neka i vaš Meni bude contactless...</h3>
                <p className='white-content'>
                  Osigurajte svojim gostima prvenstveno siguran, ali i moderan i brz način naručivanja hrane i pića u
                  Vašem objektu.
                </p>
                <Button className='btn-white'>Želite svoj cMenu?</Button>
              </div>
            </Col>
            <Col sm={24} md={15} className='mt-5'>
              <img
                src='https://thecmenu.com/wp-content/uploads/2020/11/Table-Talker-Sample-4.png'
                className='row95'
                alt='cMenu table talker'
              />
            </Col>
          </Row>
        </div>
        <Row justify='center' align='middle' className='row70 mlr-auto' ref={vidiJos}>
          <Col span={24} style={{ textAlign: 'center' }} className='mb-30'>
            <h4 className='section-title'>
              100% Jednostavno,
              <br />u samo 3 koraka...
            </h4>
          </Col>
        </Row>
        <Row justify='center' align='middle' className='row70 mlr-auto'>
          <Col sm={24} md={8} style={{ textAlign: 'center' }} className='plr-8'>
            <Card
              cover={<img src={chatIcon} className='custom-icons mlr-auto' alt='chat icon' />}
              bordered={false}
              className='ptb-30 purple-card'
            >
              <Meta
                title='1. Odaberite neki od paketa'
                description='Zavisno od Vaših potreba i veličine biznisa, možete odabrati jedan od tri ponuđena paketa.'
              />
            </Card>
          </Col>
          <Col sm={24} md={8} style={{ textAlign: 'center' }} className='plr-8'>
            <Card
              cover={<img src={formIcon} className='custom-icons mlr-auto' alt='form icon' />}
              bordered={false}
              className='ptb-30 purple-card'
            >
              <Meta
                title='2. Popunite prijavnu formu'
                description='Kroz ovu formu, mi ćemo dobiti potrebne podatke o Vašem biznisu i odmah započeti izradu materijala.'
              />
            </Card>
          </Col>
          <Col sm={24} md={8} style={{ textAlign: 'center' }} className='plr-8'>
            <Card
              cover={<img src={qrcodeIcon} className='custom-icons mlr-auto' alt='qr code icon' />}
              bordered={false}
              className='ptb-30 purple-card'
            >
              <Meta
                title='3. Materijal dobivate mailom'
                description='Putem e-maila Vam šaljemo besplatne promotivne materijale s Vašim QR kodovima.'
              />
            </Card>
          </Col>
        </Row>
        <Row className='row70 mlr-auto' ref={cta}>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            tableLayout='fixed'
            width='100%'
            className='paketiTable'
          />
        </Row>
        <Row className='row70 mlr-auto'>
          <Col span={24} style={{ textAlign: 'center' }} className='mb-30 mt-6'>
            <h4 className='section-title'>
              Postanite dio CMENU
              <br />
              platforme...
            </h4>
            <p className='p-content row50 mlr-auto pt-3 mb-30'>
              Prijavite se još danas, popunite prijavnu formu, odaberite paket koji Vam odgovara, te će Vas naši agenti
              kontaktirati s pratećim materijalom.
            </p>
            <div className='mlr-auto'>
              <Space>
                <a href='https://www.facebook.com/theCmenu' target='_blank' rel='noreferrer'>
                  <div className='social-icons facebook' style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon icon={faFacebookF} />
                  </div>
                </a>
                <a href='https://www.instagram.com/thecmenu/' target='_blank' rel='noreferrer'>
                  <div className='social-icons instagram' style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon icon={faInstagram} />
                  </div>
                </a>
                <a href='https://www.linkedin.com/company/contactless-menu/' target='_blank' rel='noreferrer'>
                  <div className='social-icons linkedin' style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </div>
                </a>
              </Space>
            </div>
          </Col>
        </Row>
      </Content>
      <Footer className='footer'>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <small style={{ color: '#ffffff' }}>
              <FontAwesomeIcon icon={faCopyright} /> 2020 <strong>thecmenu.com</strong> | POWERED BY{' '}
              <a style={{ color: '#ffffff' }} href='https://doc.ba/' target='_blank' rel='noopener noreferrer'>
                <strong>DOC.BA</strong>
              </a>
              .{' '}
            </small>
          </Col>
        </Row>
      </Footer>
    </Layout>
  )
}
export default HomePage
