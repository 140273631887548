import { CrudTypes } from './types'

const initialState = {
  loading: true,
  error: null,
  users: null,
  menus: [],
  location: [],
  locations: [],
  products: []
}

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case CrudTypes.SET_LOADING:
      return {
        ...state,
        loading: true
      }

    case CrudTypes.GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false
      }

    case CrudTypes.GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false
      }

    case CrudTypes.GET_LOCATION:
      return {
        ...state,
        location: action.payload,
        loading: false
      }

    case CrudTypes.GET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
        loading: false
      }

    case CrudTypes.GET_MENUS:
      return {
        ...state,
        menus: action.payload,
        loading: false
      }

    case CrudTypes.ADD_PRODUCT:
      return {
        ...state,
        products: [...state.products, action.payload],
        loading: false
      }

    case CrudTypes.ADD_LOCATION:
      return {
        ...state,
        locations: [...state.locations, action.payload],
        loading: false
      }

    case CrudTypes.ADD_MENU:
      return {
        ...state,
        menus: [...state.menus, action.payload],
        loading: false
      }

    case CrudTypes.EDIT_PRODUCT:
      return {
        ...state,
        products: action.payload,
        loading: false
      }

    case CrudTypes.EDIT_LOCATION:
      return {
        ...state,
        locations: action.payload,
        loading: false
      }

    case CrudTypes.EDIT_MENU:
      return {
        ...state,
        menus: action.payload,
        loading: false
      }

    case CrudTypes.DELETE_PRODUCT:
      return {
        ...state,
        products: action.payload,
        loading: false
      }

    case CrudTypes.DELETE_MENU:
      return {
        ...state,
        menus: action.payload,
        loading: false
      }

    case CrudTypes.DELETE_LOCATION:
      return {
        ...state,
        locations: action.payload,
        loading: false
      }

    case CrudTypes.CLEAR_ERRORS:
      return {
        ...state,
        error: null
      }

    default:
      return state
  }
}
