import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/**
 * Heading
 *
 * @param  props.bgImage - background image of banner url or const
 * @param  props.overlayColor - custom color with rgba, hex or hex8
 * @param  props.title - title of banner
 * @param  props.subtitle - subtitle of banner
 * @param  props.height - height of banner in pixels, default 190px
 * @param  props.link - link of banner
 */

const Heading = ({ bgImage, overlayColor, title, subtitle, height, link }) => {
  const _height = height

  // Heading Image Class
  const HeadingImage = styled.div`
    background-image: url(${bgImage});
    height: ${_height}px;
    min-height: ${_height}px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  `

  // Heading Overlay Color Class
  const HeadingOverlay = styled.div`
    background-color: ${overlayColor ? overlayColor : 'unset'};
    width: 100%;
    position: absolute;
    bottom: 0;
    vertical-align: baseline;
  `

  // Heading Text Class
  const HeadingText = styled.div`
    color: white;
    padding: 0 10px;
    margin-top: 15px;

    & h4 {
      color: white;
    }

    & span {
      display: block;
      font-size: 13px;
      line-height: 13px;
      text-transform: uppercase;
    }

    & h4 {
      font-size: 28px;
      line-height: 28px;
      text-transform: uppercase;
    }
  `

  return (
    <HeadingImage>
      <a href={link} rel='noreferrer' target='_blank'>
        <HeadingOverlay>
          <HeadingText>
            <span>{title}</span>
            <h4>{subtitle}</h4>
          </HeadingText>
        </HeadingOverlay>
      </a>
    </HeadingImage>
  )
}

export default Heading

Heading.propTypes = {
  /** Title */
  title: PropTypes.string,
  /** Sub-Title */
  subtitle: PropTypes.string,
  /** Backgound image */
  bgImage: PropTypes.string,
  /** Link to instagram */
  link: PropTypes.string,
  /** Height of heading element */
  height: PropTypes.number,
  /** Overlay color, hex, hex8 or rgba */
  overlayColor: PropTypes.string
}

Heading.defaultProps = {
  bgImage: 'https://via.placeholder.com/500x400.png/6F1A83/fff?text=doc.ba',
  link: 'https://doc.ba',
  height: 250,
  title: 'Title',
  subtitle: 'Subtitle',
  overlayColor: '#2b58abd9'
}
