// CRUD TYPES
export const CrudTypes = {
  SET_LOADING: 'SET_LOADING',
  GET_USERS: 'GET_USERS',
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_LOCATION: 'GET_LOCATION',
  GET_LOCATIONS: 'GET_LOCATIONS',
  GET_MENUS: 'GET_MENUS',
  ADD_PRODUCT: 'ADD_PRODUCT',
  ADD_LOCATION: 'ADD_LOCATION',
  ADD_MENU: 'ADD_MENU',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  EDIT_LOCATION: 'EDIT_LOCATION',
  EDIT_MENU: 'EDIT_MENU',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  DELETE_MENU: 'DELETE_MENU',
  DELETE_LOCATION: 'DELETE_LOCATION',
  CLEAR_ERRORS: 'CLEAR_ERRORS',
  GET_USERS_ERROR: 'GET_USERS_ERROR',
  GET_PRODUCTS_ERROR: 'GET_PRODUCTS_ERROR',
  GET_LOCATIONS_ERROR: 'GET_LOCATIONS_ERROR',
  GET_MENUS_ERROR: 'GET_MENUS_ERROR',
  ADD_PRODUCT_ERROR: 'ADD_PRODUCT_ERROR',
  ADD_LOCATION_ERROR: 'ADD_LOCATION_ERROR',
  ADD_MENU_ERROR: 'ADD_MENU_ERROR',
  EDIT_PRODUCT_ERROR: 'EDIT_PRODUCT_ERROR',
  EDIT_LOCATION_ERROR: 'EDIT_LOCATION_ERROR',
  EDIT_MENU_ERROR: 'EDIT_MENU_ERROR',
  DELETE_PRODUCT_ERROR: 'DELETE_PRODUCT_ERROR',
  DELETE_MENU_ERROR: 'DELETE_MENU_ERROR',
  DELETE_LOCATION_ERROR: 'DELETE_LOCATION_ERROR'
}
